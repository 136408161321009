import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'
import queryString from 'query-string'

import {
  unsubscribeArticle,
  unsubscribeAnswer,
  unsubscribeComment,
} from '@api/unsubscribePosts'
import Button from '@objects/button'
import Container from '@objects/container'
import Stage from '@components/stage'
import Headline from '@objects/headline'

function UnsubscribePostPage({ location }) {
  const intl = useIntl()
  const [error, setError] = useState(false)

  useEffect(() => {
    const query = queryString.parse(location.search)

    if (query.articleId) {
      unsubscribeArticle(parseInt(query.articleId), query.approvalId)
        .then((data) => {
          navigate(intl.formatMessage({ id: 'dialog.path' }))
        })
        .catch((e) => setError(true))
    } else if (query.answerId) {
      unsubscribeAnswer(parseInt(query.answerId), query.approvalId)
        .then((data) => {
          navigate(intl.formatMessage({ id: 'dialog.path' }))
        })
        .catch((e) => setError(true))
    } else if (query.commentId) {
      unsubscribeComment(parseInt(query.commentId), query.approvalId)
        .then((data) => {
          navigate(intl.formatMessage({ id: 'dialog.path' }))
        })
        .catch((e) => setError(true))
    } else {
      navigate(intl.formatMessage({ id: 'dialog.path' }))
    }
  }, [])

  function errorRender(error) {
    if (error) {
      return (
        <>
          <Headline>
            <FormattedMessage id="page.forward.unsubscribe.copy.error" />
          </Headline>
          <Button
            link={intl.formatMessage({ id: 'dialog.path' })}
            color={'red'}
          >
            <FormattedMessage id="button.onlinedialog" />
          </Button>
        </>
      )
    } else {
      return (
        <Headline>
          <FormattedMessage id="page.forward.unsubscribe.copy" />
        </Headline>
      )
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Stage
        alignTop={true}
        type={'generic'}
        title={intl.formatMessage({
          id: `page.forward.unsubscribe.title${error ? '.error' : ''}`,
        })}
        ariaLabel="arialabel.error"
      />

      <Container ariaLabel="arialabel.error">
        {errorRender(error)}
      </Container>
    </>
  )
}

UnsubscribePostPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  modules: PropTypes.array,
}

export default UnsubscribePostPage
