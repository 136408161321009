import apiClient from './basicApi'

function unsubscribeArticle(articleId, approvalId) {
  return apiClient
    .put(`disableinformemail/article`, {
      articleId: articleId,
      approvalId: approvalId,
    })
    .then((res) => res.data)
}

function unsubscribeAnswer(answerId, approvalId) {
  return apiClient
    .put(`disableinformemail/answer`, {
      answerId: answerId,
      approvalId: approvalId,
    })
    .then((res) => res.data)
}

function unsubscribeComment(commentId, approvalId) {
  return apiClient
    .put(`disableinformemail/comment`, {
      commentId: commentId,
      approvalId: approvalId,
    })
    .then((res) => res.data)
}

export { unsubscribeArticle, unsubscribeAnswer, unsubscribeComment }
